.header {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  transition: background-color 0.3s ease-in-out;

  @media (max-width: 1080px) {
    height: 80px;
  }
}

.header.scrolled {
  background-color: #ffffff;
}

.header.scrolled.main {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.header-bg {
  width: 100%;
  height: 0;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
}
.header-bg.open {
  height: 200%;
}

.header .header-bg:before {
  content: "";
  display: block;
  height: 1px;
  background: #e0e0e0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  -webkit-transform: translate(-50%, -1px);
  transform: translate(-50%, -1px);
}
.header .header-bg.open:before {
  width: 100%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  transition-delay: 0.2s;
}

.header-wrap {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  /* transition: all 0.2s linear; */

  @media (max-width: 1920px) {
    padding: 0 100px;
  }
  @media (max-width: 1280px) {
    padding: 0 60px;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 880px) {
    padding: 0 20px;
  }
}

.header .logo:before {
  content: "";
  width: 300px;
  height: 70px;
  display: block;
  background: url("../assets/images/logo_transparent.png") no-repeat center
    center;
  background-size: contain;

  @media (max-width: 1280px) {
    width: 260px;
  }
  @media (max-width: 1080px) {
    width: 220px;
  }
  @media (max-width: 980px) {
    width: 200px;
  }
  @media (max-width: 880px) {
    width: 180px;
  }
}
/* .header .logo:before {
  content: "";
  width: 270px;
  height: 50px;
  display: block;
  background: url("../assets/images/logo_transparent.png") no-repeat center
    center;
  background-size: contain;
} */

.header .logo.open:before,
.header .logo.open.scrolled:before,
.header .logo.scrolled:before {
  background: url("../assets/images/logo_default.png") no-repeat center center;
  background-size: contain;
}

.header .logo span {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.header a {
  color: #ffffff;
  transition: all 0.4s;
}
.header.open a,
.header.open.scrolled a,
.header.scrolled a {
  color: #111;
}

.header .header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 600px; */
  width: 100%;
  height: 100%;

  /* @media (max-width: 1740px) {
    justify-content: space-evenly;
    gap: 400px;
  }
  @media (max-width: 1540px) {
    justify-content: space-evenly;
    gap: 300px;
  }
  @media (max-width: 1420px) {
    justify-content: space-evenly;
    gap: 220px;
  }

  @media (max-width: 1150px) {
    gap: 150px;
  }
  @media (max-width: 980px) {
    gap: 100px;
  }

  @media (max-width: 880px) {
    gap: 50px;
  } */
}

.header .header-box > nav {
  height: 100%;
  /* width: 1000px; */
}
.header .header-box > nav > .main-menu {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1080px) {
    padding-right: 50px;
  }
}

.header .header-box > nav > .main-menu > li {
  padding: 0 60px;
  position: relative;
  height: 100%;
  flex-shrink: 0;

  @media (max-width: 1080px) {
    padding: 0 50px;
  }
  @media (max-width: 890px) {
    padding: 0 40px;
  }
}
.header .header-box > nav > .main-menu > li:hover > a {
  color: var(--primary-c);
  font-weight: 700;
}
.header .header-box > nav > .main-menu > li > a {
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.7rem;
  font-weight: 500;
  position: relative;
  height: 100%;
}
.header .header-box > nav > .main-menu > li:hover > a:after {
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.header .header-box > nav > .main-menu > li > a:after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: var(--primary-c);
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0s;
  transition: all 0s;
}

.header nav .sub-menu {
  transition: all 0s;
  gap: 40px;
  padding: 0 60px;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 0;

  @media (max-width: 1280px) {
    gap: 35px;
  }
  @media (max-width: 1080px) {
    padding: 0 50px;
    gap: 30px;
  }
  @media (max-width: 890px) {
    padding: 0 40px;
  }
}

.header nav .sub-menu.open {
  height: 100%;
  transition: all 0.3s 0.2s;
}

.header nav .sub-menu.open li:hover a {
  font-weight: 700;
  color: var(--primary-c);
}
/* .header nav .sub-menu.open li {
  transform: translateX(0);
}
.header nav .sub-menu > li {
  transform: translateX(20px);
} */
.header nav .sub-menu > li > a {
  font-size: 1.7rem;
  color: #333333;
  font-weight: 400;
  white-space: nowrap;

  @media (max-width: 1280px) {
    font-size: 1.5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer {
  background: #353535;
  /* border-top: 1px solid var(--border-c); */
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  background-blend-mode: overlay;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 234px;
  padding: 0 20px;
}
.footer > div {
  width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer .footer_info {
  display: flex;
  flex-direction: column;
  padding-top: 31px;
  gap: 20px;
}

.footer .footer_info p {
  line-height: 22px;
}
.footer > div > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .footer_btn_box_wrap {
  padding-top: 28px;
  align-items: flex-end;
}
.footer .footer_btn_box {
  display: flex;
  gap: 10px;
}
.footer .footer_btn_box button {
  color: #fff;
}
