@import "./styles/reset.css";
@import url("https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css");
@import "animate.css";

* {
  box-sizing: border-box;
  max-height: 1000000px;
}

html {
  font-size: 62.5%;
}

@media (max-width: 1200px) {
  html {
    font-size: 56.25%;
  }
}

body {
  font-family: "NanumSquare", "나눔스퀘어", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "NanumSquare";
  font-weight: 300;
  src: url("./fonts/NanumSquareL.ttf");
}

@font-face {
  font-family: "NanumSquare";
  font-weight: 400;
  src: url("./fonts/NanumSquareR.ttf");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 700;
  src: url("./fonts/NanumSquareB.ttf");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 800;
  src: url("./fonts/NanumSquareEB.ttf");
}

@font-face {
  font-family: "NanumPen";
  font-weight: 400;
  src: url("./fonts/NanumPen.ttf");
}

:root {
  --background: #ffffff;
  --primary-c: #00a5d8;
  --primary-c-hover: #186fb2;
  --text-c: #363636;
  --light-text-c: #4e4e4e;
  --border-c: #9b9b9b;
  --footer-bg-c: #333333;
  --footer-text-c: #333333;
  --banner-c: #222b7e;
  --container: 1600px;
  --slider-height: 1010px;
  --font-size: 8rem;
}

#root {
  max-width: 100%;
  width: 100%;
}

.container {
  /* min-width: var(--container); */
  /* width: var(--container); */
  margin: 0 auto;
}

.wrap {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

input,
textarea,
button {
  font-family: "NanumSquare", "나눔스퀘어", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}
input::placeholder {
  font-family: "NanumSquare", sans-serif;
}
input[type="password"] {
  font-family: "Roboto", sans-serif;
}
input:-ms-input-placeholder {
  font-family: "NanumSquare", sans-serif;
}
input::-ms-input-placeholder {
  font-family: "NanumSquare", sans-serif;
}

.bold {
  font-weight: 700;
}

.primary_text_color {
  color: var(--primary-c);
}

.font55 {
  --font-size: 5.5rem;
  font-size: var(--font-size);
}
.font70 {
  --font-size: 7rem;
  font-size: var(--font-size);
}
.font80 {
  --font-size: 8rem;
  font-size: var(--font-size);
}

@media (max-width: 1400px) {
  .font55 {
    font-size: calc(var(--font-size) * 0.78);
  }
  .font70 {
    font-size: calc(var(--font-size) * 0.78);
  }
}
